export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Catalogs',
    route: null,
    icon: 'DatabaseIcon',
    children: [
      {
        title: 'Coupons',
        route: null,
        icon: 'Link2Icon',
        children: [
          {
            title: 'Coupon Type',
            route: 'coupon-type',
            icon: 'Link2Icon',
          },
          {
            title: 'Coupon Catalog',
            route: 'coupon-catalog',
            icon: 'Link2Icon',
          },
          {
            title: 'Coupon',
            route: 'coupon',
            icon: 'Link2Icon',
          },
        ],
      },
      {
        title: 'User',
        route: 'null',
        icon: 'UsersIcon',
        children: [
          {
            title: 'User',
            route: 'user',
            icon: 'UserIcon',
          },
          {
            title: 'Group',
            route: 'group',
            icon: 'UsersIcon',
          },
        ],
      },
    ],
  },
]
